body {
    margin: 0 auto;
    font-family: Verdana, Arial, Helvetica, sans-serif;
    font-size: 16px;
}

.guiContent {
    position: absolute;
    width: 100%;
    bottom: 15px;
}

.message {
    display: flex;
    animation: MoveUpDown 1.5s ease-in-out infinite;
    justify-content: center;
    text-align: center;
    margin-bottom: 20px;
    color: #1f86c3;
    width: 100%;
}

.messageContent {
    width: 80%;
}

.xrControlButtons {
    display: flex;
    width: 100%;
    justify-content: center;
}

.buttonSpacer {
    width: 10px;
}

@keyframes MoveUpDown {
    0%, 100% {
        margin-bottom: 40px;
    }
    50% {
        margin-bottom: 50px;
    }
}