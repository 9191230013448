.button {
    background-color: Transparent;
    padding: 15px 32px;
    border: 2px solid #1f86c3;
    border-radius: 4px;
    color: #1f86c3;
    font-size: 16px;
}

.button-invisible {
    display: none;
}

.button:disabled {
    border: 2px solid #C3C0BA;
    color: #C3C0BA;
}